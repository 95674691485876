<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" order-sm="1">
				<v-subheader>Nueva carta</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevaCarta
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="carta"
          :espacios="espacios"
        />
      </v-col>
      <v-col cols="12" md="8" order-sm="1">
        <v-subheader>Listado cartas</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="cartas.filter(a => !loading)"
          :headers="cartasHeader"
          class="fixed-checkbox"
          item-key="idCarta"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="cartas"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            {{ index+1 }}
            <!-- <td style="white-space: nowrap !important;padding-left: 22px !important;">{{ index+1 }}</td> -->
          </template>
          <template v-slot:item.precio="{ item, index }">
            {{ parseFloat(item.precio).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click="carta = item"
                small
                rounded
                color="secondary">
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click="deleteCarta(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
		
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    espacios: Array,
    cartas: Array
  },
  components: {
    NuevaCarta: () => import("./NuevaCarta.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      carta: null,
      inlineFilters: {},
      keyHelper: 0,
      cartasHeader: [
        { text: "Nº", value: "n", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Espacio", value: "espacioShow", dataType: "select", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Nombre", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Año", value: "year", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Precio base", value: "precio", cellClass: "pa-1 text-no-wrap font-weight-bold text-right" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    carta() {
      this.keyHelper++;
    },
  },
  methods: {
    parseDate,
    async deleteCarta({ idCarta, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Carta: ${nombre}`,
        `ATENCIÓN: Todos los pricings basados en esta carta se eliminarán. ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar la carta?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/cartas/" + idCarta,
      });
      this.$emit("reload")
    }
  },
};
</script>